.middle-modal h3 {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #494949;
}

.digit {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 32px;
  color: #328CD6;
}

.counter {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 32px;
  color: #328CD6;
}

.chart {
  margin-top: 20px;
}

.recharts-cartesian-axis-tick line {
  display: none;
  /* X ekseni çizgilerini gizler */
}

.recharts-bar-rectangle {
  border-radius: 5px;
  /* Çubuk kenarlarını yuvarlatır */
}